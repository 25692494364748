import React, { useEffect, useState } from 'react';
import { Calendar, Modal, Badge, Layout, Menu } from 'antd';
import { filterDateInformation_API } from '../../functions/user';
import 'jspdf-autotable';  // ใช้สำหรับการสร้างตารางใน PDF
import PrintReport from './servicePages/printReport';

const { Sider, Content } = Layout;

const EditBookingForm = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [filterData, setfilterData] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [bookingData, setbookingData] = useState(null);
  const [menuKey, setMenuKey] = useState('3'); // กำหนดค่าเริ่มต้นให้เป็นเมนูที่ 3 (ปฏิทินการจอง)

  useEffect(() => {
    filterDateInformation_API()
      .then((res) => {
        setbookingData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  
  const getListData = (value) => {
    const formattedDate = value.format('YYYY-MM-DD');
    const dataForDate = bookingData?.filter((item) => item.date === formattedDate);
    return dataForDate || [];
  };

  const onSelectDate = (value) => {
    const newDate = value.format('YYYY-MM-DD');
    const filter = bookingData?.filter((d) => d.date === newDate);
    setfilterData(filter);
    setSelectedDate(newDate);
    setIsModalVisible(true); // เปิด Modal ทันทีเมื่อเลือกวันที่
  };

  const dateCellRender = (value) => {
    const listData = getListData(value);
    return (
      <ul className="events">
        {listData.map((item) => (
          <Badge key={item.type} status={item.type} text={item.content} />
        ))}
      </ul>
    );
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleMenuClick = ({ key }) => {
    setMenuKey(key); // เปลี่ยนสถานะเมนูที่ถูกเลือก
  };

  // เนื้อหาของแต่ละเมนู
  const renderContent = () => {
    switch (menuKey) {
      case '1':
        return (
          <div>
            <h2>ผู้ที่ลงทะเบียนทั้งหมด</h2>
            {/* แสดงผู้ลงทะเบียนทั้งหมด */}
            <p>แสดงรายชื่อผู้ลงทะเบียนทั้งหมดที่นี่</p>
          </div>
        );
      case '2':
        return (
          <div>
            <h2>ผู้ลงทะเบียนใหม่</h2>
            {/* ฟอร์มสำหรับผู้ลงทะเบียนใหม่ */}
            <p>แสดงฟอร์มการลงทะเบียนใหม่ที่นี่</p>
          </div>
        );
      case '3':
        return (
          <div>
            <h2>ปฏิทินการจอง</h2>
            <div style={{ maxWidth: '100%', overflowX: 'auto', marginBottom: 20 }}>
              <Calendar
                cellRender={dateCellRender}
                onSelect={onSelectDate}
                style={{ width: '100%', maxWidth: '100%' }}
              />
            </div>

            <Modal title="ยืนยันการจอง" open={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
              <p>วันที่: {selectedDate}</p>
              <h6>รายชื่อผู้ที่ลงทะเบียน</h6>
              {filterData && filterData.length > 0 ? (
                filterData[0].user ? (
                  <ul>
                    {filterData[0].user.map((user, index) => (
                      <li key={index}>{user}</li>
                    ))}
                  </ul>
                ) : (
                  <p>ไม่มีผู้ลงทะเบียน</p>
                )
              ) : (
                <p>ไม่มีข้อมูลสำหรับวันที่นี้</p>
              )}
            </Modal>
          </div>
        );
      case '4':
        return (
          <div>
            <h2>คำขอยกเลิก</h2>
            {/* จัดการคำขอยกเลิก */}
            <p>แสดงคำขอยกเลิกที่นี่</p>
          </div>
        );
      case '5':
        return (
         PrintReport()
        );
      case '6':
        return (
          <div>
            <h2>ติดต่อผู้ดูแลระบบ</h2>
            {/* แสดงข้อมูลการติดต่อผู้ดูแลระบบ */}
            <p>แสดงข้อมูลการติดต่อผู้ดูแลระบบที่นี่</p>
          </div>
        );
      default:
        return <p>กรุณาเลือกเมนู</p>;
    }
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      {/* Sidebar สำหรับเมนู */}
      <Sider width={200} className="site-layout-background">
        <Menu
          mode="inline"
          selectedKeys={[menuKey]}
          style={{ height: '100%', borderRight: 0 }}
          onClick={handleMenuClick}
        >
          <Menu.Item key="1">ผู้ลงทะเบียนทั้งหมด</Menu.Item>
          <Menu.Item key="2">ผู้ลงทะเบียนใหม่</Menu.Item>
          <Menu.Item key="3">ปฏิทินการจอง</Menu.Item>
          <Menu.Item key="4">คำขอยกเลิก</Menu.Item>
          <Menu.Item key="5">พิมพ์รายงาน</Menu.Item>
          <Menu.Item key="6">ติดต่อผู้ดูแลระบบ</Menu.Item>
        </Menu>
      </Sider>

      {/* Content หลัก */}
      <Layout style={{ padding: '0 24px 24px' }}>
        <Content
          style={{
            padding: 24,
            margin: 0,
            minHeight: 280,
          }}
        >
          {renderContent()}
        </Content>
      </Layout>
    </Layout>
  );
};

export default EditBookingForm;
