import React from 'react';

function UserMain() {
    return (
        <div className="container mt-5">
            <div className="alert alert-secondary" role="alert">
                <h4 className="alert-heading">ระเบียบการใช้บริการ</h4>
                <hr />
                <p>
                    1. เทศบาลตากให้บริการดูดสิ่งปฏิกูล เฉพาะในเขตเทศบาลตาก
                </p>
                <p>
                    2. ให้ผู้ประสงค์ขอใช้บริการดูดสิ่งปฏิกูล กรอกข้อมูลในแบบฟอร์มการขอใช้บริการให้ถูกต้องและครบถ้วน เพื่อความสะดวกและรวดเร็วในการให้บริการ
                </p>
                <p>
                    3. เนื่องจากเจ้าหน้าที่ตรวจสอบระบบการขอใช้บริการได้เฉพาะวันและเวลาราชการ ในการให้บริการดูดสิ่งปฏิกูล จึงต้องอยู่ภายใต้เงื่อนไขดังนี้
                </p>
                <ul>
                    <li>
                        3.1 กรณีกรอกข้อมูลในวันและเวลาราชการ เทศบาลจะดำเนินการให้ตามวันที่ขอให้ดำเนินการ ทั้งนี้ ต้องกำหนดวันที่ขอให้ดำเนินการถัดจากวันกรอกข้อมูลอย่างน้อย 1 วัน
                    </li>
                    <li>
                        3.2 กรณีที่กรอกข้อมูลนอกวันและเวลาราชการ หรือวันหยุดนักขัตฤกษ์ เทศบาลจะดำเนินการให้ในวันแรกที่เปิดทำการ
                    </li>
                </ul>
                <p>
                    4. ผู้ประสงค์ขอใช้บริการดูดสิ่งปฏิกูล ที่กรอกและบันทึกข้อมูลเรียบร้อยแล้ว สามารถตรวจสอบวันดำเนินการได้ ที่เมนูตรวจสอบวันดำเนินการ
                </p>
                <hr />
                <h5>ค่าธรรมเนียมการเก็บและขนสิ่งปฏิกูล</h5>
                <ul>
                    <li>
                        - อัตรา ลูกบาศก์เมตรละ 200 บาท
                    </li>
                    <li>
                        - เศษที่เหลือไม่เกิน ครึ่งลูกบาศก์เมตร 100 บาท
                    </li>
                    <li>
                        - เศษที่เกินครึ่งลูกบาศก์เมตรให้คิดเท่ากับ 1 ลูกบาศก์เมตร
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default UserMain;
