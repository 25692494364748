import React, { useEffect, useState } from 'react';
import { Steps, Form, Input, Select, Button, Calendar, Badge } from 'antd';
import { formUser_API ,filterDateInformation_API } from '../../functions/user';
import { toast } from 'react-toastify';



const BookingForm = ({history}) => {
  const [current, setCurrent] = useState(0);
  const [form] = Form.useForm();
  const [selectedDate, setSelectedDate] = useState(null);

  const [bookingData, setbookingData] = useState(null);
  const { Option } = Select;
  const { Step } = Steps;

  const [fromUser, setFormUser] = useState({});

  useEffect(() => {
    filterDateInformation_API().then(res => {
      setbookingData(res.data);
      console.log(res.data);
    }).catch(err => {
      console.log(err);
    });
  }, []);

  const onValuesChange = (changedValues, allValues) => {
    setFormUser(allValues);
  };

  const getListData = (value) => {
    const formattedDate = value.format('YYYY-MM-DD');
    const dataForDate = bookingData.filter(item => item.date === formattedDate);
    return dataForDate || [];
  };

  const onSelectDate = (value) => {
    const newDate = value.format('YYYY-MM-DD');

    setSelectedDate(newDate);
  };

  const dateCellRender = (value) => {
    const listData = getListData(value);
    return (
      <ul className="events">
        {listData.map((item) => (
          <Badge key={item.type} status={item.type} text={item.content} />
        ))}
      </ul>
    );
  };


  const formItemLayout = {
    labelCol: { xs: { span: 24 }, sm: { span: 8 } },
    wrapperCol: { xs: { span: 24 }, sm: { span: 16 } },
  };

  const next = async () => {
    try {
      // ตรวจสอบฟอร์มในขั้นตอนปัจจุบัน
      if (current === 0) {
        await form.validateFields(); // ตรวจสอบว่าฟอร์มในขั้นตอนนี้ถูกต้องหรือไม่
      }
      setCurrent(current + 1);
    } catch (error) {
      toast.error('โปรดตรวจสอบข้อมูลให้ถูกต้องก่อนบันทึก', { autoClose: 2000, });

      console.log("Validation failed:", error);
    }
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const onFinish = () => {
    const formData = { ...fromUser, selectedDate: selectedDate };
    formUser_API(formData).then(res => {
      toast.success('เข้าสู่ระบบสำเร็จ!',{ autoClose: 2000,}); // แสดงข้อความ 'success'
      history.push('/home')
      console.log(res);
    }).catch(err => {
      console.log(err);
    });
  };

  const steps = [
    {
      title: 'ข้อมูลส่วนตัว',
      content: (
        <>
          <Form.Item name="name" label="ชื่อ" rules={[{ required: true, message: 'Please input your name!' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="type" label="ประเภทสิ่งก่อสร้าง" rules={[{ required: true, message: 'Please select construction type!' }]}>
            <Select placeholder="Select construction type">
              <Option value="บ้านเรือน">บ้านเรือน</Option>
              <Option value="สถานประกอบการ">สถานประกอบการ</Option>
              <Option value="อนุเคราะห์">อนุเคราะห์</Option>
            </Select>
          </Form.Item>
          <Form.Item name="numberHome" label="บ้านเลขที่" rules={[{ required: true, message: 'Please input house number!' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="village" label="หมู่บ้าน" rules={[{ required: true, message: 'Please input village!' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="soi" label="ซอย" rules={[{ required: true, message: 'Please input soi!' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="alley" label="ถนน" rules={[{ required: true, message: 'Please input street!' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="phoneNumber" label="เบอร์โทรศัพท์" rules={[{ required: true, message: 'Please input phone number!' }]}>
            <Input />
          </Form.Item>
        </>
      ),
    },
    {
      title: 'เลือกวันที่จอง',
      content: (
        <>
          <div style={{ maxWidth: '100%', overflowX: 'auto', marginBottom: 20 }}>
            <Calendar
              cellRender={dateCellRender}
              onSelect={onSelectDate}
              style={{ width: '100%', maxWidth: '100%' }}
            />
          </div>
        </>
      ),
    },
    {
      title: 'ยืนยันการจอง',
      content: (
        <>
          <p>ยืนยันการจองของคุณที่เลือก</p>
        </>
      ),
    },
  ];

  return (
    <Form
      {...formItemLayout}
      form={form}
      name="register"
      onFinish={onFinish}
      onValuesChange={onValuesChange}
      initialValues={{ prefix: '86' }}
      style={{ maxWidth: 1000, margin: 'auto', marginTop: '20px', overflow: 'auto', padding: '20px' }}
      scrollToFirstError
    >
      <Steps current={current}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>

      <div style={{ marginTop: 20, padding: '20px', minHeight: '200px' }}>
        {steps[current].content}
      </div>

      <div style={{ marginTop: 20, textAlign: 'center' }}>
        {current < steps.length - 1 && (
          <Button type="primary" onClick={next}>
            Next
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button type="primary" htmlType="submit">
            Done
          </Button>
        )}
        {current > 0 && (
          <Button style={{ margin: '0 8px' }} onClick={prev}>
            Previous
          </Button>
        )}
      </div>

     
    </Form>
  );
};

export default BookingForm;
