import  axios from'axios'

export const formUser_API = async (group) => {
 
    const url = `${process.env.REACT_APP_API}/bookingData`;
  
    console.log(url)
    const headers = { authToken:'null' };
    const data = {formUser:group}
  
    try {
      const response = await axios.post(url,data, { headers });
  
      return response.data;
    } catch (error) {
      console.error("Error creating person:", error);
      throw new Error("[API Error]ไม่สามารถส่งข้อมูลไปบันทึกได้");
    }
  };
export const filterDateInformation_API = async () => {
 
    const url = `${process.env.REACT_APP_API}/filterDateInformation_API`;
  
    console.log(url)
    const headers = { authToken:'null' };
  
    try {
      const response = await axios.get(url, { headers });
  
      return response;
    } catch (error) {
      console.error("Error creating person:", error);
      throw new Error("[API Error]ไม่สามารถส่งข้อมูลไปบันทึกได้");
    }
  };