import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { LoginHeadler } from '../../functions/auth';
import { useDispatch } from 'react-redux';
import UserMain from '../2user/userMain';
import { Button } from 'antd';

function Login({ history }) {
    
    const dispatch = useDispatch();
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [fromUser, setUser] = useState({
        username: '',
        password: '',
    });

    const onChange = (e) => {
        setUser({ ...fromUser, [e.target.name]: e.target.value });
    };

    const roleBasedRedirect = (res) => {
        if (res === 'admin') {
            history.push('/EditBookingForm');
        } else {
            history.push('/user/dashboard');
        }
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        setButtonDisabled(true);
        const User = {
            name: fromUser.username,
            password: fromUser.password,
        };
        try {
            await LoginHeadler(User).then(res => {
                toast.success('เข้าสู่ระบบสำเร็จ!', { autoClose: 2000 });

                dispatch({
                    type: 'LOGGED_IN_USER',
                    payload: {
                        token: res.data.token,
                        name: res.data.payload.name,
                        role: res.data.payload.role,
                    },
                });

                localStorage.setItem('token', res.data.token);
                roleBasedRedirect(res.data.payload.role);
            });
        } catch (err) {
            console.log(err);
            toast.error('เกิดข้อผิดพลาด login ', { autoClose: 2000 });
            setButtonDisabled(false);
        }
    };

    // เช็ค localStorage เมื่อ mount
    useEffect(() => {
        const idTokenResult = localStorage.getItem('token');
        if (!idTokenResult) {
            history.push('/login');  // ถ้าไม่มี token ให้ redirect ไปที่หน้า login
        }
    }, [history]);

    const idTokenResult = localStorage.getItem('token');
    return idTokenResult ? (
        <UserMain />
    ) : (
        <div className="container p-5">
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <h1>เข้าสู่ระบบ</h1>
                    <form className="w-100" onSubmit={onSubmit}>
                        <div className="form-floating mb-3">
                            <input
                                type="text"
                                className="form-control"
                                id="username"
                                name="username"
                                placeholder="name@example.com"
                                value={fromUser.username}
                                onChange={onChange}
                            />
                            <label htmlFor="username">Email address</label>
                        </div>
                        <div className="form-floating">
                            <input
                                type="password"
                                className="form-control"
                                id="password"
                                name="password"
                                placeholder="Password"
                                value={fromUser.password}
                                onChange={onChange}
                            />
                            <label htmlFor="password">Password</label>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                            <Button 
                                type="primary" 
                                htmlType="submit" 
                                size="large" 
                                disabled={buttonDisabled} 
                                style={{ width: '100%', height: '50px', fontSize: '18px' }}
                            >
                                ยืนยัน
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Login;
