import React from 'react';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';  // ใช้สำหรับการสร้างตารางใน PDF

function PrintReport() {

  // ข้อมูลตัวอย่าง
  const bookingData = [
    { name: 'อติกานต์', email: 'atikant@example.com', selectedDate: '2023-09-10' },
    { name: 'กิตติ', email: 'kitti@example.com', selectedDate: '2023-09-11' },
    { name: 'พรทิพย์', email: 'porntip@example.com', selectedDate: '2023-09-12' },
    { name: 'จันทร์เจ้า', email: 'jan@example.com', selectedDate: '2023-09-13' },
  ];

  // เพิ่มฟอนต์ภาษาไทย
  const generatePDF = () => {
    const doc = new jsPDF();



    // เพิ่มข้อความหัวข้อใหญ่
    doc.setFontSize(24);
    doc.text('Report DEMO', 105, 15, null, null, 'center');

    // เพิ่มข้อมูลเพิ่มเติม เช่น วันที่
    doc.setFontSize(12);
    doc.text(`Print date: ${new Date().toLocaleDateString()}`, 10, 30);

    // สร้างตารางจากข้อมูล bookingData
    if (bookingData && bookingData.length > 0) {
      const data = [
        ['name', 'E-mail', 'Date'], // หัวตาราง
        ...bookingData.map((item) => [
          item.name,
          item.email || 'ไม่มีข้อมูล',
          item.selectedDate,
        ]),
      ];

      doc.autoTable({
        head: [data[0]], // หัวตาราง
        body: data.slice(1), // ข้อมูลในตาราง
        startY: 40, // เริ่มตารางที่ตำแหน่ง Y
        theme: 'grid', // ธีมของตาราง
        headStyles: { fillColor: [100, 100, 255] }, // กำหนดสีหัวตาราง
        bodyStyles: { fillColor: [240, 240, 240] }, // กำหนดสีพื้นหลังของตาราง
        alternateRowStyles: { fillColor: [255, 255, 255] }, // สีพื้นหลังแถวที่สลับกัน
        margin: { top: 30 },
      });

      // ดาวน์โหลด PDF
      doc.save('report.pdf');
    } else {
      console.log('ไม่มีข้อมูลสำหรับการพิมพ์รายงาน');
    }
  };

  return (
    <div style={{ padding: '20px', textAlign: 'center' }}>
      <h2 style={{ marginBottom: '20px' }}>พิมพ์รายงาน PDF</h2>
      <p>คุณสามารถพิมพ์รายงานการลงทะเบียนผู้ใช้งานได้โดยคลิกที่ปุ่มด้านล่าง:</p>  
       <p>ติดปัญหาเรื่องfontภาษาไทย:</p>
      <button
        onClick={generatePDF}
        style={{
          padding: '10px 20px',
          fontSize: '16px',
          backgroundColor: '#4CAF50',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
        }}
      >
        พิมพ์รายงานเป็น PDF
      </button>
    </div>
  );
}

export default PrintReport;
